import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import styles from './main.module.scss'

function Index(props) {
  const { t, i18n } = useTranslation()

  const getDiscussLink = (lang) => {
    switch (lang) {
      case 'zh-tw':
        return `/${props.match.params.lang}/questionnaire/P5cmlHMZ`
      case 'zh-hk':
        return `/${props.match.params.lang}/questionnaire/RuFzpDhg`
    }
  }

  return (
    <div className={styles['main-wrapper']}>
      {/* <div className={styles['title-wrapper']}>
        <img src={require('../../assets/pc_title.png').default} />
      </div> */}

      <div className={styles['chatbox-wrapper']}>
        <picture>
          <source
            media="(min-width: 651px)"
            srcSet={require('../../assets/pc_game2_chat.svg').default}
          />
          <source
            media="(max-width: 650px)"
            srcSet={require('../../assets/m_game2_chat.svg').default}
          />
          <img src={require('../../assets/pc_game2_chat.svg').default} />
        </picture>
        <div className={styles['chatbox-inner-wrapper']}>
          <div className={styles['message-wrapper']}>
            <div className={styles['message']}>
              <Trans i18nKey="game2.msg"></Trans>
            </div>
            {/* <div className={styles['message']}>
              劇場遊戲
              <br />
              <span>原先用來訓練演員</span>，<br />
              後來有人發現呢啲遊戲對演員以
              <br />
              外嘅人都好有幫助，
              <br />
              包括
              <span>
                提升對身體的覺察、想
                <br />
                像力、直覺同反應
              </span>
              ，<br />
              於是劇場遊戲就被廣泛應用喇！
              <br />
            </div> */}
            <div className={styles['desc']}>
              <Trans i18nKey="game2.desc"></Trans>
            </div>
          </div>
          <div className={styles['buttons-wrapper']}>
            <Link
              to={`/${props.match.params.lang}/book`}
              className={`btn ${styles['button-wrapper']} ${styles['btn-book']}`}
            ></Link>
            <Link
              to={`/${props.match.params.lang}/workshop`}
              className={`btn ${styles['button-wrapper']} ${styles['btn-workshop']}`}
            ></Link>
            <a
              href={'http://tefo.hk'}
              target="_blank"
              className={`btn ${styles['button-wrapper']} ${styles['btn-web']}`}
            ></a>
            <Link
              to={`/${props.match.params.lang}/video`}
              className={`btn ${styles['button-wrapper']} ${styles['btn-video']}`}
            ></Link>
            <Link
              to={`/${props.match.params.lang}`}
              className={`btn ${styles['button-wrapper']} ${styles['btn-restart']}`}
            ></Link>
            <Link
              target="_blank"
              to={getDiscussLink(props.match.params.lang)}
              className={`btn ${styles['button-wrapper']} ${styles['btn-forum']}`}
            ></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        i18n: state.i18n,
      },
    }
  })(Index)
)
