export default (
  state = {
    languageCode: 'zh-hk'
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_I18n':
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    default:
      return state
  }
}
