import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import Youtube from 'react-youtube'

import styles from './index.module.scss'

import videoList from './video-list.json'

function Index(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

  const getVideoList = () => {
    let videoDOM = []
    for (let video of videoList.list) {
      videoDOM.push(
        <div className={styles['video-wrapper']}>
          <div className={styles['youtube-player']}>
            <Youtube
              videoId={video.youtubeid}
              opts={{
                height: '200',
                width: '320',
              }}
            />
          </div>
          <div className={styles['name']}>{video.name}</div>
        </div>
      )
    }
    return videoDOM
  }

  const handleBtnLeft = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1)
    } else {
      setCurrentVideoIndex(videoList.list.length - 1)
    }
  }

  const handleBtnRight = () => {
    if (currentVideoIndex < videoList.list.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1)
    } else {
      setCurrentVideoIndex(0)
    }
  }

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['inner-wrapper']}>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-inner-wrapper']}>
            <div>
              <span>劇場遊戲</span>館藏
            </div>
          </div>
        </div>
        {/* {screenWidth && screenWidth > 641 ? (
          <div className={styles['video-section']}>
            <div className={styles['videos-wrapper']}>{getVideoList()}</div>
          </div>
        ) : ( */}
        <div className={styles['video-section']}>
          <div className={styles['video-section-inner-wrapper']}>
            <div
              className={`btn ${styles['btn-left']}`}
              onClick={() => handleBtnLeft()}
            >
              <img src={require('../../assets/video/arrow-left.svg').default} />
            </div>
            <div className={styles['youtube-player']}>
              <Youtube
                videoId={videoList.list[currentVideoIndex].youtubeid}
                opts={{
                  height:
                    screenWidth > 640 ? ((screenWidth * 0.4) / 16) * 9 : 200,
                  width:
                    screenWidth > 641 ? screenWidth * 0.4 : screenWidth * 0.7,
                }}
              />
              <div className={styles['name']}>
                {videoList.list[currentVideoIndex].name}
              </div>
            </div>
            <div
              className={`btn ${styles['btn-right']}`}
              onClick={() => handleBtnRight()}
            >
              <img
                src={require('../../assets/video/arrow-right.svg').default}
              />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  )
}

export default Index
