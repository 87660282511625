export default (
  state = {
    show: false,
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_CREDIT':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }
    default:
      return state
  }
}
