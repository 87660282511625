import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import Youtube from 'react-youtube'

import styles from './index.module.scss'

import content_json from './content.json'

function Index(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const getYoutubeOpts = () => {
    if (screenWidth < 640) {
      return {
        width: screenWidth * 0.8,
        height: 160,
      }
    } else if (screenWidth < 1025) {
      return {
        width: 300,
        height: 180,
      }
    } else {
      return {
        width: 400,
        height: 260,
      }
    }
  }

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['scroll-wrapper']}>
        <div className={styles['inner-wrapper']}>
          <div className={styles['title-wrapper']}>
            <div className={styles['title-inner-wrapper']}>
              <div>劇場遊戲</div>
              <div className={styles['sub-title']}>資料庫</div>
            </div>
          </div>
          <div className={styles['content-wrapper']}>
            <div className={`${styles['books-wrapper']}`}>
              {content_json.books.map((book) => (
                <div
                  className={`${styles['book-wrapper']} ${
                    book.youtubeid ? styles['w-video'] : ''
                  }`}
                >
                  <div className={styles['image-wrapper']}>
                    <img
                      src={require(`../../assets/book/${book.image}`).default}
                    />
                  </div>
                  <div className={styles['desc-wrapper']}>
                    <div className={styles['desc-inner-wrapper']}>
                      <p>
                        <span>書名：</span>
                        {book.name}
                      </p>
                      <p>
                        <span>出版社：</span>
                        {book.press}
                      </p>
                      {book.author && (
                        <p>
                          <span>作者：</span>
                          {book.author}
                        </p>
                      )}
                      <p>
                        <span>初版發行日期：</span>
                        {book.publishDate}
                      </p>
                    </div>
                  </div>
                  {book.youtubeid && (
                    <div className={styles['youtube-wrapper']}>
                      <Youtube
                        videoId={book.youtubeid}
                        opts={getYoutubeOpts()}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles['websites-wrapper']}>
              {content_json.websites.map((website) => (
                <div className={styles['website-wrapper']}>
                  <div className={styles['image-wrapper']}>
                    <img
                      src={require('../../assets/book/url-icon.svg').default}
                    />
                  </div>
                  <div className={styles['desc-wrapper']}>
                    <p>
                      <span>網頁：</span>
                      <span>{website.name}</span>
                    </p>
                    {/* <p>
                      <span>作者：</span>
                      {website.author}
                    </p> */}
                    <p>
                      <span>網址：</span>
                      <a href={website.url} target="_blank">
                        {website.url}
                      </a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className={styles['videos-wrapper']}>
              {content_json.videos.map((video) => (
                <div className={styles['video-wrapper']}>
                  <div className={styles['youtube-wrapper']}>
                    <Youtube
                      videoId={video.youtubeid}
                      opts={getYoutubeOpts()}
                    />
                  </div>
                  <div className={styles['desc-wrapper']}>
                    <p>
                      <span>影片：</span>
                      {video.name}
                    </p>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
