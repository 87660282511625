import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './element.module.scss'

function Index(props) {
  const [currentStyle, setCurrentStyle] = useState({})
  const [startStyleConfig, setStartStyleConfig] = useState({})
  const [endStyleConfig, setEndStyleConfig] = useState({})
  const [availableStyle, setAvailableStyle] = useState([])

  useEffect(() => {
    let availableAnimateStyle = Object.keys(props.startStyle).filter((e) =>
      Object.keys(props.endStyle).includes(e)
    )
    setAvailableStyle(availableAnimateStyle)

    // let tempStartConfig = convertStyleToConfig(props.startStyle)
    // let tempEndConfig = convertStyleToConfig(props.endStyle)
    setStartStyleConfig(props.startStyle)
    setEndStyleConfig(props.endStyle)

  }, [])

  useEffect(() => {
    calcCurrentStyle()
  }, [props.current, availableStyle])

  const convertStyleToConfig = (styleObject) => {
    let tempConfig = {}
    for (let s of Object.keys(styleObject)) {
      tempConfig[s] = {
        unit:
          typeof styleObject[s] === 'number'
            ? '%'
            : styleObject[s].substr(-2, 2),
        config:
          typeof styleObject[s] === 'number'
            ? styleObject[s]
            : parseInt(styleObject[s].substring(0, styleObject[s].length - 2)),
      }
    }
    return tempConfig
  }

  const convertConfigToStyle = (config) => {
    return `${config.config}${config.unit}`
  }

  const calcCurrentStyle = () => {
    let tempCurrentStyle = {}
    for (let s of availableStyle) {
      tempCurrentStyle[s] = getCurrentStyleConfig(s)
    }
    setCurrentStyle(tempCurrentStyle)
  }

  const getCurrentStyleConfig = (s) => {
    let currentPosition = startStyleConfig[s].config

    if (
      startStyleConfig[s].startAt != undefined &&
      endStyleConfig[s].endAt != undefined
    ) {
      let duration = endStyleConfig[s].endAt - startStyleConfig[s].startAt

      if (
        props.current >= startStyleConfig[s].startAt &&
        props.current <= endStyleConfig[s].endAt
      ) {
        if (startStyleConfig[s].config < endStyleConfig[s].config) {
          currentPosition +=
            (Math.abs(endStyleConfig[s].config - startStyleConfig[s].config) /
              duration) *
            (props.current - startStyleConfig[s].startAt)
        } else if (startStyleConfig[s].config > endStyleConfig[s].config) {
          currentPosition -=
            (Math.abs(endStyleConfig[s].config - startStyleConfig[s].config) /
              duration) *
            (props.current - startStyleConfig[s].startAt)
        }
      } else if (
        props.current > startStyleConfig[s].startAt &&
        props.current >= endStyleConfig[s].endAt
      ) {
        currentPosition = endStyleConfig[s].config
      }
    } else if (
      startStyleConfig[s].startAt == undefined &&
      endStyleConfig[s].endAt == undefined
    ) {
      if (startStyleConfig[s].config < endStyleConfig[s].config) {
        currentPosition +=
          (Math.abs(endStyleConfig[s].config - startStyleConfig[s].config) /
            100) *
          props.current
      } else if (startStyleConfig[s].config > endStyleConfig[s].config) {
        currentPosition -=
          (Math.abs(endStyleConfig[s].config - startStyleConfig[s].config) /
            100) *
          props.current
      }
    }

    return convertConfigToStyle({
      unit: startStyleConfig[s].unit,
      config: currentPosition,
    })
  }

  return (
    <div
      style={currentStyle}
      className={`${styles['translate-config']} ${props.className}`}
    >
      {props.children}
    </div>
  )
}

export default Index
