import React, { Component, Fragment, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import i18n from '../i18next'

import Header from './Header/index'
import Landing from './Landing/index'
import Welcome from './Welcome/index'
import Questionnaire from './Questionnaire/index'
import Game1 from './Game1/index'
import Game2 from './Game2/index'
import Credit from './Credit/index'
import Workshop from './Workshop/index'
import Video from './Video/index'
import Book from './Book/index'

import * as i18nAction from '../redux/actions/i18nAction'

function RouteMain(props) {
  useEffect(() => {
    i18n.changeLanguage(props.match.params.lang)
    props.dispatch(
      i18nAction.updateValue({
        name: 'languageCode',
        value: props.match.params.lang,
      })
    )
  }, [])

  return (
    <Fragment>
      <Header />
      <Switch>
        <Route exact path={`${props.match.path}`} component={Landing} />
        <Route exact path={`${props.match.path}/welcome`} component={Welcome} />
        <Route
          exact
          path={`${props.match.path}/questionnaire/:formid(xYoNqhao|ujTZgEIv|eUpymV8G|SEr6yJlg|BhiEK61Z|HAUpdTVT|RuFzpDhg|QNUXWxJX|G9HW0iuU|VsyDjpIa|GjjcDI3G|P5cmlHMZ)`}
          component={Questionnaire}
        />
        <Route exact path={`${props.match.path}/game1`} component={Game1} />
        <Route exact path={`${props.match.path}/game2`} component={Game2} />
        <Route
          exact
          path={`${props.match.path}/workshop`}
          component={Workshop}
        />
        <Route exact path={`${props.match.path}/video`} component={Video} />
        <Route exact path={`${props.match.path}/book`} component={Book} />
        <Redirect exact from="*" to="/" />
      </Switch>
      {props.state.credit.show ? <Credit /> : <Fragment />}
    </Fragment>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        i18n: state.i18n,
        credit: state.credit,
      },
    }
  })(RouteMain)
)
