import React, { Component, Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router'

function LanguageNotFound(props) {

  useEffect(() => {
    props.history.push('/zh-hk')
  }, [])

  return <Fragment />
}

export default withRouter(LanguageNotFound)
