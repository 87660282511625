import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import i18n from '../../i18next'

import styles from './index.module.scss'

import * as i18nAction from '../../redux/actions/i18nAction'
import * as CreditActions from '../../redux/actions/creditAction'

function Index(props) {
  const [menuOpen, setMenuOpen] = useState(false)

  const showCredit = () => {
    props.dispatch(
      CreditActions.updateValue({
        name: 'show',
        value: true,
      })
    )
  }

  const handleMenuButtons = (url) => {
    props.history.push(`${props.match.url}${url}`)
    setMenuOpen(false)
  }

  function toggleLanguage(newLanguage) {
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage)
    }
    // switch (newLanguage) {
    //   case 'zh-tw':
    //     props.dispatch(
    //       i18nAction.updateValue({ name: 'languageCode', value: 'zh-tw' })
    //     )
    //     break
    //   case 'zh-hk':
    //     props.dispatch(
    //       i18nAction.updateValue({ name: 'languageCode', value: 'zh-hk' })
    //     )
    //     break
    // }
    props.history.push(
      // `${props.location.pathname.replace(props.match.params.lang, newLanguage)}`
      `/${newLanguage}`
    )
    setMenuOpen(false)
  }

  return (
    <div className={styles['main-wrapper']}>
      <div
        className={`${styles['menu-main-wrapper']} ${
          menuOpen ? styles['open'] : ''
        }`}
      >
        <div className={styles['menu-inner-wrapper']}>
          <div className={styles['logo-wrapper']}>
            <img src={require('../../assets/logo.png').default} />
          </div>
          <div
            className={`btn ${styles['btn-menu']} ${
              menuOpen ? styles['hide'] : ''
            }`}
            onClick={() => setMenuOpen(true)}
          >
            <img src={require('../../assets/btn_menu.svg').default} />
          </div>
          <div
            className={`${styles['menu-wrapper']} ${
              !menuOpen ? styles['hide'] : ''
            }`}
          >
            <div
              className={`btn ${styles['btn-home']} ${styles['menu-button-wrapper']}`}
              onClick={() => handleMenuButtons('')}
            >
              <div className={styles['icon-wrapper']}>
                <img
                  src={require('../../assets/btn-menu-home-icon.svg').default}
                />
              </div>
              <div className={styles['text']}>劇場遊戲博物館入口</div>
            </div>
            <div
              className={`btn ${styles['btn-book']} ${styles['menu-button-wrapper']}`}
              onClick={() => handleMenuButtons('/book')}
            >
              <div className={styles['icon-wrapper']}>
                <img
                  src={require('../../assets/btn-menu-book-icon.svg').default}
                />
              </div>
              <div className={styles['text']}>劇場遊戲資料庫</div>
            </div>
            <div
              className={`btn ${styles['btn-video']} ${styles['menu-button-wrapper']}`}
              onClick={() => handleMenuButtons('/video')}
            >
              <div className={styles['icon-wrapper']}>
                <img
                  src={require('../../assets/btn-menu-video-icon.svg').default}
                />
              </div>
              <div className={styles['text']}>劇場遊戲館藏</div>
            </div>
            <div
              className={`btn ${styles['btn-workshop']} ${styles['menu-button-wrapper']}`}
              onClick={() => handleMenuButtons('/workshop')}
            >
              <div className={styles['icon-wrapper']}>
                <img
                  src={
                    require('../../assets/btn-menu-workshop-icon.svg').default
                  }
                />
              </div>
              <div className={styles['text']}>劇場遊戲工作坊</div>
            </div>
            <div
              className={`btn ${styles['btn-credit']} ${styles['menu-button-wrapper']}`}
              onClick={() => showCredit()}
            >
              <div className={styles['icon-wrapper']}>
                <img
                  src={require('../../assets/btn-menu-credit-icon.svg').default}
                />
              </div>
              <div className={styles['text']}>創作團隊名單</div>
            </div>
            <div className={styles['languages-wrapper']}>
              <div
                className={`btn ${styles['language-button']} ${
                  props.match.params.lang == 'zh-hk' && styles['active']
                }`}
                onClick={() => toggleLanguage('zh-hk')}
              >
                廣東口語版
              </div>
              <div>/</div>
              <div
                className={`btn ${styles['language-button']} ${
                  props.match.params.lang == 'zh-tw' && styles['active']
                }`}
                onClick={() => toggleLanguage('zh-tw')}
              >
                繁體中文版
              </div>
            </div>
          </div>
          <div
            className={`btn ${styles['btn-menu-close']} ${
              !menuOpen ? styles['hide'] : ''
            }`}
            onClick={() => setMenuOpen(false)}
          >
            <img src={require('../../assets/btn-menu-close.svg').default} />
          </div>
        </div>
      </div>
      <div className={styles['title']}>
        <div className={styles['title-inner-wrapper']}>劇場遊戲博物館</div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        i18n: state.i18n,
      },
    }
  })(Index)
)
