import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import i18n from '../../i18next'

import styles from './index.module.scss'

function Index(props) {
  const handleWelcomeButton = (newLanguage) => {
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage)
    }
    props.history.push(`/${newLanguage}/welcome`)
  }

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['enter-wrapper']}>
        <div className={styles['title-wrapper']}>
          <img src={require('../../assets/pc_title.png').default} />
        </div>
        <div
          onClick={() => handleWelcomeButton('zh-hk')}
          className={`btn ${styles['btn-enter']} ${styles['btn-hk']}`}
        ></div>
        <div
          onClick={() => handleWelcomeButton('zh-tw')}
          className={`btn ${styles['btn-enter']} ${styles['btn-tw']}`}
        ></div>
      </div>
      <div className={styles['bottom-item-wrapper']}>
        <div className={styles['sponsor-wrapper']}>
          <img src={require('../../assets/sponsor_logo.png').default} />
        </div>
        <div className={styles['fence-wrapper']}>
          <picture>
            <source
              media="(min-width: 651px)"
              srcSet={require('../../assets/pc_front_object.png').default}
            />
            <source
              media="(max-width: 650px)"
              srcSet={require('../../assets/m_front_object.png').default}
            />
            <img src={require('../../assets/pc_front_object.png').default} />
          </picture>
        </div>
      </div>
    </div>
  )
}

export default Index
