import React, { useEffect, useState, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import RouteLanguage from './Route-Language'
import RouteMain from './Route-Main'

import store from '../redux/index'

function App(props) {
  return (
    <Provider store={store}>
      <Router basename={process.env.REACT_APP_Router_BaseName}>
        <Suspense fallback="...">
          <RouteLanguage />
        </Suspense>
      </Router>
    </Provider>
  )
}

export default App
