import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './index.module.scss'

import * as CreditActions from '../../redux/actions/creditAction'

function Index(props) {
  const closeCredit = () => {
    props.dispatch(
      CreditActions.updateValue({
        name: 'show',
        value: false,
      })
    )
  }

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['inner-wrapper']}>
        <div className={styles['center-wrapper']}>
          <div className={styles['title-wrapper']}>
            <div className={styles['title-inner-wrapper']}>
              <div>創作團隊</div>
              <div className={styles['orange']}>名單</div>
            </div>
          </div>
          <div className={styles['content-wrapper']}>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>項目製作：</div>
              <div className={styles['text']}>
                香港教育劇場論壇（TEFO）
                <br />
                <span className={styles['link']}>http://www.tefo.hk/</span>
              </div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>項目策劃及總監：</div>
              <div className={styles['text']}>譚文晶</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>項目統籌：</div>
              <div className={styles['text']}>鄧惠儀</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>項目設計及執行：</div>
              <div className={styles['text']}>廖佩芳、陳膺國、謝偉倫、鄧惠儀</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>項目顧問：</div>
              <div className={styles['text']}>歐怡雯</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>影片拍攝及製作：</div>
              <div className={styles['text']}>羅芷茵</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>演員：</div>
              <div className={styles['text']}>郭泳鈺、吳泳鍶、陳舒穎</div>
            </div>
            <div className={`${styles['credit-wrapper']} ${styles['space']}`}>
              <div className={styles['title']}>網站製作：</div>
              <div className={styles['text']}>媚川有限公司</div>
            </div>
            <div className={styles['credit-wrapper']}>
              <div className={styles['title']}>宣傳團隊：</div>
              <div className={styles['text']}>媚川有限公司</div>
            </div>
          </div>
          <div
            className={`btn ${styles['btn-close']}`}
            onClick={() => closeCredit()}
          >
            <img src={require('../../assets/btn-close-white.svg').default} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {},
    }
  })(Index)
)
