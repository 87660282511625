import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import styles from './index.module.scss'

function Index(props) {
  const { t, i18n } = useTranslation()
  const [stage, setStage] = useState('next')

  const changeStage = () => {
    let currentStage = stage
    switch (currentStage) {
      case 'next':
        setStage('ready')
        break
      case 'ready':
        switch (props.match.params.lang) {
          case 'zh-hk':
            props.history.push({
              pathname: `/${props.match.params.lang}/questionnaire/SEr6yJlg`,
            })
            break
          case 'zh-tw':
            props.history.push({
              pathname: `/${props.match.params.lang}/questionnaire/QNUXWxJX`,
            })
            break
        }
        break
    }
  }

  const getChatboxBg = () => {
    let currentStage = stage
    switch (currentStage) {
      case 'next':
        return 'scene2'
      case 'ready':
        return 'scene3'
    }
  }

  return (
    <div className={`${styles['main-wrapper']} ${styles[`stage-${stage}`]}`}>
      <div className={styles['interactive-wrapper']}>
        <div className={styles['chatbox-wrapper']}>
          <div className={styles['chatbox-inner-wrapper']}>
            <picture>
              <source
                media="(min-width: 651px)"
                srcSet={
                  require(`../../assets/pc_${getChatboxBg()}_chatbox.svg`)
                    .default
                }
              />
              <source
                media="(max-width: 650px)"
                srcSet={
                  require(`../../assets/m_${getChatboxBg()}_chatbox.svg`)
                    .default
                }
              />
              <img
                src={
                  require(`../../assets/pc_${getChatboxBg()}_chatbox.svg`)
                    .default
                }
              />
            </picture>
            <div className={styles['message-wrapper']}>
              <img
                src={require('../../assets/pc_title.png').default}
                className={styles['title']}
              />
              {stage === 'next' ? (
                <div
                  className={`${styles['message-content']} ${styles['message']}`}
                >
                  <Trans i18nKey="welcome.msg_1"></Trans>
                </div>
              ) : (
                <Fragment />
              )}
              {stage === 'ready' ? (
                <div
                  className={`${styles['message-content']} ${styles['message2']}`}
                >
                  <div className={styles['intro']}>
                    <Trans i18nKey="welcome.msg_2"></Trans>
                  </div>
                  <div className={styles['prepare-item-wrapper']}>
                    <div className={styles['item-wrapper']}>
                      <div className={styles['image-wrapper']}>
                        <img
                          src={require('../../assets/headphone.svg').default}
                        />
                      </div>
                      <div className={styles['name']}>
                        <Trans i18nKey="welcome.earphone.title"></Trans>
                      </div>
                      <div className={styles['desc']}>
                        <Trans i18nKey="welcome.earphone.desc"></Trans>
                      </div>
                    </div>
                    <div className={styles['item-wrapper']}>
                      <div className={styles['image-wrapper']}>
                        <img src={require('../../assets/hand.svg').default} />
                      </div>
                      <div className={styles['name']}>
                        <Trans i18nKey="welcome.finger.title"></Trans>
                      </div>
                      <div className={styles['desc']}>
                        <Trans i18nKey="welcome.finger.desc"></Trans>
                      </div>
                    </div>
                    <div className={styles['item-wrapper']}>
                      <div className={styles['image-wrapper']}>
                        <img src={require('../../assets/time.svg').default} />
                      </div>
                      <div className={styles['name']}>
                        <Trans i18nKey="welcome.time.title"></Trans>
                      </div>
                      <div className={styles['desc']}>
                        <Trans i18nKey="welcome.time.desc"></Trans>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
              <style>
                {`
                  .${styles[`stage-${stage}`]} .${styles['button']}{
                      background-image: url(${
                        require(`../../assets/btn-ready-${props.match.params.lang}.png`)
                          .default
                      });
                  }

                  .${styles[`stage-${stage}`]} .${styles['button']}:hover{
                      background-image: url(${
                        require(`../../assets/btn-ready-hover-${props.match.params.lang}.png`)
                          .default
                      });
                  }
                `}
              </style>
              <div
                className={`btn ${styles['button']}`}
                onClick={() => changeStage()}
              ></div>
            </div>
          </div>
        </div>
        <div className={styles['lincoln-wrapper']}>
          <img src={require('../../assets/m_lincoln.png').default} />
          {stage == 'ready' ? (
            <img
              src={require('../../assets/lincoln_glasses.png').default}
              className={styles['eye-glasses']}
            />
          ) : (
            <Fragment />
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        i18n: state.i18n,
      },
    }
  })(Index)
)
