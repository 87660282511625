import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import styles from './index.module.scss'

function Index(props) {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['inner-wrapper']}>
        <div className={styles['title-wrapper']}>
          <div className={styles['title-inner-wrapper']}>
            <div>劇場遊戲</div>
            <div className={styles['sub-title']}>工作坊</div>
          </div>
        </div>
        <div className={styles['content']}>
          <p>
            <Trans i18nKey="workshop.content_1"></Trans>
          </p>
          <p>
            <Trans i18nKey="workshop.content_2"></Trans>
          </p>
          <div className={styles['workshops-wrapper']}>
            <div className={styles['workshop-wrapper']}>
              <div className={styles['title']}>
                <Trans i18nKey="workshop.workshop_1.title"></Trans>
              </div>
              <div className={styles['desc']}>
                <Trans i18nKey="workshop.workshop_1.desc"></Trans>
              </div>
            </div>
            <div className={styles['workshop-wrapper']}>
              <div className={styles['title']}>
                <Trans i18nKey="workshop.workshop_2.title"></Trans>
              </div>
              <div className={styles['desc']}>
                <Trans i18nKey="workshop.workshop_2.desc"></Trans>
              </div>
              <div className={styles['ps']}>
                ＊線上培訓時，參加者須開鏡頭參與互動
              </div>
            </div>
          </div>
          <p className={styles['text-center']}>
            <Trans i18nKey="workshop.ps"></Trans>
          </p>
          <div
            className={`btn ${styles['btn-register']}`}
            onClick={() =>
              props.history.push(
                `/${props.match.params.lang}/questionnaire/HAUpdTVT`
              )
            }
          >
            <img
              src={require('../../assets/workshop/btn-register.svg').default}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
