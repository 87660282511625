import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './index.module.scss'

function Index(props) {
  const [typeformID, setTypeformID] = useState(null)

  useEffect(() => {
    setTypeformID(props.match.params.formid)
  }, [])

  return (
    <div className={styles['main-wrapper']}>
      <iframe
        id="typeform-full"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src={`https://form.typeform.com/to/${typeformID}?typeform-medium=embed-snippet`}
      ></iframe>
    </div>
  )
}

export default Index
