import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './index.module.scss'

import Element from './element'

function Index(props) {
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth)
  const scrollDivRef = useRef(null)
  const [scrollPersentage, setScrollPersentage] = useState(0)
  const scrollEvent = (e) => {
    setScrollPersentage(
      (scrollDivRef.current.scrollTop /
        (scrollDivRef.current.scrollHeight -
          scrollDivRef.current.clientHeight)) *
        100
    )
  }

  const handleBtnNext = () => {
    switch (props.match.params.lang) {
      case 'zh-hk':
        props.history.push({
          pathname: `/${props.match.params.lang}/questionnaire/xYoNqhao`,
        })
        break
      case 'zh-tw':
        props.history.push({
          pathname: `/${props.match.params.lang}/questionnaire/G9HW0iuU`,
        })
        break
    }
  }

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['background-wrapper']}></div>
      {displayWidth && displayWidth > 641 ? (
        <Fragment>
          <Element
            className={styles['bg-text-wrapper']}
            startStyle={{
              left: {
                unit: '%',
                config: -270,
              },
            }}
            endStyle={{
              left: {
                unit: '%',
                config: 40,
              },
            }}
            current={scrollPersentage}
          >
            <img src={require('../../assets/pc_game1_bg_text.png').default} />
          </Element>
          <Element
            className={styles['person1-wrapper']}
            startStyle={{
              left: {
                unit: '%',
                config: -35,
              },
            }}
            endStyle={{
              left: {
                unit: '%',
                config: 5,
              },
            }}
            current={scrollPersentage}
          >
            <img src={require('../../assets/pc_game1_person1.png').default} />
          </Element>
          <Element
            className={styles['person2-wrapper']}
            startStyle={{
              right: {
                unit: '%',
                config: -38,
              },
            }}
            endStyle={{
              right: {
                unit: '%',
                config: -5,
              },
            }}
            current={scrollPersentage}
          >
            <img src={require('../../assets/pc_game1_person2.png').default} />
          </Element>
          <Element
            className={styles['light-wrapper']}
            startStyle={{
              opacity: {
                unit: '',
                config: 0,
                startAt: 95,
              },
            }}
            endStyle={{
              opacity: {
                unit: '',
                config: 1,
                endAt: 100,
              },
            }}
            current={scrollPersentage}
          >
            <img src={require('../../assets/light.png').default} />
          </Element>
          <Element
            className={styles['btn-next-wrapper']}
            startStyle={{
              opacity: {
                unit: '',
                config: 0,
                startAt: 90,
              },
              'z-index':{
                unit: '',
                config: 0,
                startAt: 90,
              }
            }}
            endStyle={{
              opacity: {
                unit: '',
                config: 1,
                endAt: 100,
              },
              'z-index':{
                unit: '',
                config: 100,
                endAt: 100,
              }
            }}
            current={scrollPersentage}
          >
            <div onClick={() => handleBtnNext()}>
              <img src={require('../../assets/btn-next.png').default} />
            </div>
          </Element>
          <Element
            className={styles['scroll-text-wrapper']}
            startStyle={{
              opacity: {
                unit: '',
                config: 1,
                startAt: 90,
              },
            }}
            endStyle={{
              opacity: {
                unit: '',
                config: 0,
                endAt: 100,
              },
            }}
            current={scrollPersentage}
          >
            <img src={require('../../assets/pc_ins.png').default} />
          </Element>
        </Fragment>
      ) : (
        <Fragment>
          <Fragment>
            <Element
              className={styles['bg-text-wrapper']}
              startStyle={{
                top: {
                  unit: '%',
                  config: 40,
                },
              }}
              endStyle={{
                top: {
                  unit: '%',
                  config: -130,
                },
              }}
              current={scrollPersentage}
            >
              <img src={require('../../assets/m_game1_bg_text.png').default} />
            </Element>
            <Element
              className={styles['person1-wrapper']}
              startStyle={{
                top: {
                  unit: '%',
                  config: 80,
                },
              }}
              endStyle={{
                top: {
                  unit: '%',
                  config: 50,
                },
              }}
              current={scrollPersentage}
            >
              <img src={require('../../assets/m_game1_person1.png').default} />
            </Element>
            <Element
              className={styles['person2-wrapper']}
              startStyle={{
                bottom: {
                  unit: '%',
                  config: 80,
                },
              }}
              endStyle={{
                bottom: {
                  unit: '%',
                  config: 50,
                },
              }}
              current={scrollPersentage}
            >
              <img src={require('../../assets/m_game1_person2.png').default} />
            </Element>
            <Element
              className={styles['light-wrapper']}
              startStyle={{
                opacity: {
                  unit: '',
                  config: 0,
                  startAt: 95,
                },
              }}
              endStyle={{
                opacity: {
                  unit: '',
                  config: 1,
                  endAt: 100,
                },
              }}
              current={scrollPersentage}
            >
              <img src={require('../../assets/light.png').default} />
            </Element>
            <Element
              className={styles['btn-next-wrapper']}
              startStyle={{
                opacity: {
                  unit: '',
                  config: 0,
                  startAt: 90,
                },
                'z-index':{
                  unit: '',
                  config: 0,
                  startAt: 90,
                }
              }}
              endStyle={{
                opacity: {
                  unit: '',
                  config: 1,
                  endAt: 100,
                },
                'z-index':{
                  unit: '',
                  config: 100,
                  endAt: 100,
                }
              }}
              current={scrollPersentage}
            >
              <div onClick={() => handleBtnNext()}>
                <img src={require('../../assets/btn-next.png').default} />
              </div>
            </Element>
            <Element
              className={styles['scroll-text-wrapper']}
              startStyle={{
                opacity: {
                  unit: '',
                  config: 1,
                  startAt: 90,
                },
              }}
              endStyle={{
                opacity: {
                  unit: '',
                  config: 0,
                  endAt: 100,
                },
              }}
              current={scrollPersentage}
            >
              <img src={require('../../assets/m_ins.png').default} />
            </Element>
          </Fragment>
        </Fragment>
      )}
      <div
        className={styles['scroll-wrapper']}
        ref={scrollDivRef}
        onScroll={scrollEvent}
      >
        <div className={styles['scroll-content']}></div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        i18n: state.i18n,
      },
    }
  })(Index)
)

