import React, { Component, Fragment, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { withRouter } from 'react-router'

import RouteMain from './Route-Main'
import LanguageNotFound from './LanguageNotFound.js'

function RouteLanguage(props) {
  return (
    <Fragment>
      <Switch>
        <Route path="/:lang(zh-hk|zh-tw)" component={RouteMain} />
        <Route
          render={() => {
            return <LanguageNotFound />
          }}
        />
      </Switch>
    </Fragment>
  )
}

export default withRouter(RouteLanguage)
