import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './index.module.scss'

import Curtain from './curtain'
import Main from './main'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <Curtain></Curtain>
      <Main></Main>
    </div>
  )
}

export default Index
